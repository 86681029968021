.suggestions-container {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1; /* Ensure it's above other content */
    top: 100%; /* position it right below the input */
    left: 0; /* align it with the left of the input */
    margin-left: 0px !important;
    border-radius: 0px !important;
}

#layers-search-component-input-group {
    border-radius: 0px;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0px;
}