.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1); /* Light gray */
    border-top: 8px solid black; /* Black */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
